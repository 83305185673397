@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('./toast-fix.css');

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* light theme custom */
[data-theme='light'] {
  background: #eef1f7;
}

[data-theme='dark'] {
  background: #000000;
}

[data-theme='light'] .table :where(thead, tfoot) :where(th, td) {
  color: hsla(var(--bc) / var(--tw-text-opacity, 1));
  font-weight: unset;
  font-size: 1.05rem;
  background-color: unset;
  text-transform: unset;
}

[data-theme='light'] .table :where(tbody th, tbody td) {
  --tw-bg-opacity: 1;
  background-color: unset;
}

.table-dense table.table :where(thead, tfoot) tr :where(th, td) {
  border-radius: unset;
  border-right: 1px solid hsl(var(--bc) / 0.1);
  background: hsl(var(--b1) / var(--tw-bg-opacity));
}

.tab.tab-active {
  border-color: hsl(var(--p) / var(--tw-text-opacity));
}

/* --------------------- Override Original Color --------------------------  */
.tabs-boxed .tab-active {
  background-color: #f2f4f7;
  color: #333;
}
.tabs-boxed .tab-active:hover {
  color: #333;
}
/* --------------------- Override Original Color --------------------------  */

[data-theme='light'] .divider-x {
  border-bottom: 1px solid #e5e7eb;
}

[data-theme='light'] .divider-y {
  border-right: 1px solid #e5e7eb;
}

[data-theme='dark'] .divider-x {
  border-bottom: 1px solid #222;
}

[data-theme='dark'] .divider-y {
  border-right: 1px solid #222;
}

#root .react-datepicker-popper {
  z-index: 15;
}

.z-markdown img {
  margin: 0;
}

.published-button:hover {
  .when-hover {
    display: inline-block;
  }
  .when-not-hover {
    display: none;
  }
}

.published-button {
  .when-hover {
    display: none;
  }
  .when-not-hover {
    display: inline-block;
  }
}

.cm-editor {
  height: 100%;
}
